import React, { useContext } from "react";
import styles from "./Formatters.module.css";
import InfoTooltip from '../../shared/InfoTooltip/InfoTooltip';
import { SessionContext } from "../../../context/SessionContext";
import { useClaimInvoiceStore } from "../lib/state";

const HeaderFormatter = ({ col, centered = false, toolTipRequired = false, toolTipMessage = "", fieldName = '' }) => {
    const {  getCurrentTabAndSubtab } = useContext(SessionContext);
    const context = useContext(SessionContext);

    const getMessage = (fieldName, accountName) => {
        if (fieldName === 'repair') {
            return 'Available repair options are determined by the asset and component selection. To complete a unit replacement, select the asset without a component then select unit replaced as the repair.'
        } else if (accountName === 'Lennox' && fieldName === 'rate') {
            return 'The rate is defaulted to purchased tier labor rate.'
        } else if (accountName != 'Lennox' && fieldName === 'rate') {
            return 'The rate is defaulted to purchased plan labor rate.'
        } else if (fieldName === 'refrigerantPrice') {
            return 'For refrigerant enter price per pound. For reclaim enter a flat rate.';
        } else if (fieldName === 'oem' && coverageType == 27) {
            return 'If under OEM Warranty is checked, please enter the part price knowing the cost of the part and markup will NOT be included for reimbursement. The part total will default to $0.'
        } else {
            return 'If under OEM Warranty is checked, please enter the part price knowing the cost of the part will NOT be included for reimbursement. The part total will equal markup only.'
        }
    }
    const message = getMessage(fieldName, context.accountName);  
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const [contract] = useClaimInvoiceStore((state) => [
        state.getContract
    ]);

    const currentContract = typeof contract === 'function' ? contract() : contract;
    const coverageType = currentContract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 28)?.[0]?.value;

    return (
        <div className={centered ? styles.toolTipAlignCenter : styles.toolTipAlignFlex}>
            {col.text}
            {toolTipRequired && (
                <span aria-hidden="true" className={`${styles.toolTipSection} pl-2`}>
                    <InfoTooltip iconType="info" message={message} />
                </span>
            )}
        </div>
    );
}

export default HeaderFormatter;