import React, { useEffect, useState } from 'react';
import { Container, Card, Col } from 'reactstrap'
import { Row } from 'react-bootstrap';
import moment from 'moment';
import DownloadButton from "../shared/IconButtons/DownloadButton";
import { phoneFormat, convertToCamelCase } from '../shared/util';
import ComfortShieldLogo from './Logo/ComfortShield_Logo.svg';
import SnadminLogo from './Logo/Snadmin_Logo.svg';
import { getTranslation } from '../../components/Global/helpers';

const CertificateCoverage = ({ styles, assetDetails, languageCode }) => {
    const [cocTranslation, setCocTranslation] = useState({});

    const dateFormat = (date) => {
        return moment(date).format('DD/MM/yyyy')
    }

    const languagePath = {
        "en-ca": "English/CA",
        "en-us": "English",
        "fr-ca": "French/CA",
        "fr-us": "French"
    }

    useEffect(() => {
        const cocContent = getTranslation(`COC/Lennox/${languagePath[languageCode]}/components/COC.json`)
            .then(response => response.json());
        const coverageDetail = getTranslation(`COC/Lennox/${languagePath[languageCode]}/components/CoverageType.json`)
            .then(response => response.json());
        const assetDetails = getTranslation(`COC/Lennox/${languagePath[languageCode]}/components/AssetDetails.json`)
            .then(response => response.json());
        Promise.all([cocContent, coverageDetail, assetDetails]).then(data => {
            setCocTranslation({
                cocContent: data?.[0],
                coverageDetail: data?.[1],
                assetDetails: data?.[2]
            });
        })
    }, [])

    return (
        <Container className={`contentWrapper ${styles.contentWrapper}`}>
            <Card className={`${styles.cardWrapper} p-3`}>
                <Row className={`m-0 ${styles.imageContainer}`}>
                    <Col lg="7" md="6">
                        <div className={styles.imageWrapper}>
                            <img
                                tabIndex={0}
                                className={styles.dealerImageContainer}
                                src={ComfortShieldLogo}
                                alt="comfortshield"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className={styles.imageWrapper}>
                            <img
                                tabIndex={0}
                                className={styles.customerImageContainer}
                                src={SnadminLogo}
                                alt="SNAdmin"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={`m-0 mb-4`}>
                    <Col className={`${styles.headerContainer} ${styles.addressWrapper}`} lg="7" md="6">
                        <div className='mb-2'>
                            <span className={styles.addressHeader}>{cocTranslation?.cocContent?.sellerHeading || 'DEALER'}:</span>
                        </div>
                        <div>{assetDetails?.dealerName}</div>
                        <div>{assetDetails?.dealerAddress1}</div>
                        {assetDetails?.dealerAddress2 && <div> {assetDetails?.dealerAddress2}</div>}
                        <div>{`${assetDetails?.dealerCity} ${assetDetails?.dealerStateAbbreviation} ${assetDetails.dealerZip}`} </div>
                    </Col>
                    <Col className={styles.addressWrapper}>
                        <div className='mb-2'>
                            <span className={styles.addressHeader}>{cocTranslation?.cocContent?.issuedHeading || 'ISSUED TO'}:</span>
                        </div>
                        <div>{assetDetails?.customerName}</div>
                        <div>{assetDetails?.customerAddress1}</div>
                        {assetDetails?.customerAddress2 && <div> {assetDetails?.customerAddress2}</div>}
                        <div>{`${assetDetails?.customerCity} ${assetDetails?.customerStateAbbreviation} ${assetDetails.customerZip}`} </div>
                    </Col>
                    {/* <Col className={styles.downloadWrapper}>
                         <DownloadButton
                            alt='Download'
                            onClick={() => { }}
                        /> 
                    </Col> */}
                </Row>
                <div className={styles.boxWrapper}>
                    <div className='p-3'>
                        <p className={styles.headerWrapper}>{cocTranslation?.cocContent?.certificateTitle || 'CERTIFICATE OF COVERAGE'}</p>
                        <div className={styles.flexCenter}>
                            <hr className={`${styles.horizontalWrapper} mt-2 mb-3`} />
                        </div>
                        <div>
                            <div className={styles.introWrapper}>
                                {cocTranslation?.cocContent?.introParagraph || `Thank you for purchasing a Comfort Shield Extended Service 
                                    Agreement. This Extended Service Agreement is a valuable tool in helping you avoid unexpected repair expenses.`}
                            </div>
                        </div>
                        <div className={styles.flexCenter}>
                            <hr className={`${styles.horizontalWrapper} mt-2 mb-3`} />
                        </div>

                        <div className={`${styles.wrapperDetails} mb-4`}>
                            <div>
                                <span className={styles.wrapperTitle}>
                                    <strong>{`${cocTranslation?.cocContent?.planNum || 'CERTIFICATE NUMBER'}: `}</strong>
                                </span>
                                <span>
                                    <strong>{assetDetails?.contractNumber}</strong>
                                </span>
                            </div>
                            <div>
                                <span className={styles.wrapperTitle}>
                                    <strong>{`${cocTranslation?.cocContent?.retailPrice || 'RETAIL CONTRACT PRICE'}: `}</strong>
                                </span>
                                <span>
                                    <strong>{cocTranslation?.cocContent?.retailPriceAmt || 'Please refer to your sales receipt or invoice.'}</strong>
                                </span>
                            </div>
                            <div>
                                <span className={styles.wrapperTitle}>
                                    <strong>{`${cocTranslation?.cocContent?.typeOfCoverage || 'TYPE OF COVERAGE'}: `}</strong>
                                </span>
                                <span>
                                    <strong>{cocTranslation?.coverageDetail?.[convertToCamelCase(assetDetails?.coverageType)] || assetDetails?.coverageType}</strong>
                                </span>
                            </div>
                        </div>
                        <div className={styles.productTableWrapper}>
                            <div className={`${styles.productDetailWrapper} m-0`}>
                                <div className={`${styles.tableWrapper} d-flex`}>
                                    <Col className={`${styles.textContainer} col-2`}>
                                        <strong>{cocTranslation?.cocContent?.assetName || 'PRODUCT NAME'}</strong>
                                    </Col>
                                    <Col className={`${styles.textContainer} col-2`} >
                                        <strong>{cocTranslation?.cocContent?.assetBrand || 'PRODUCT MANUFACTURER'}</strong>
                                    </Col>
                                    <Col className={`${styles.textContainer} col-2`}>
                                        <strong>{cocTranslation?.cocContent?.modelNumber || 'MODEL NUMBER'}</strong>
                                    </Col>
                                    <Col className={`${styles.textContainer} col-2`}>
                                        <strong>{cocTranslation?.cocContent?.serialNumber || 'SERIAL NUMBER'}</strong>
                                    </Col>
                                    <Col className={`${styles.textContainer} col-2`}>
                                        <strong>{cocTranslation?.cocContent?.effectiveDate || 'EFFECTIVE DATE'}</strong>
                                    </Col>
                                    <Col className={`${styles.textContainer} col-2`}>
                                        <strong>{cocTranslation?.cocContent?.expDate || 'EXPIRATION DATE'}</strong>
                                    </Col>
                                </div>
                                <div className={styles.flexCenter}>
                                    <hr className={`${styles.horizontalWrapper} mt-2 mb-3`} />
                                </div>
                                {assetDetails?.assetDetails?.map(data => {
                                    return <div className={`${styles.tableWrapper} d-flex mb-2`}>
                                        <Col className='col-2'>{cocTranslation?.assetDetails?.[convertToCamelCase(data.assetName)] || data.assetName}</Col>
                                        <Col className='col-2'>{data.brandName}</Col>
                                        <Col className='col-2'>{data.modelNumber}</Col>
                                        <Col className='col-2'>{data.serialNumber}</Col>
                                        <Col className='col-2'>{dateFormat(data.startDate)}</Col>
                                        <Col className='col-2'>{dateFormat(data.expirationDate)} </Col>
                                    </div>
                                })}
                            </div>
                        </div>
                        <Row className='m-0'>
                            <div className={styles.wrapperHeader}>{`${cocTranslation?.cocContent?.forService || 'FOR REPAIR SERVICE CALL'}: ${phoneFormat(assetDetails?.dealerPhone)}`}</div>
                        </Row>
                        <Row className='m-0'>
                            <p className={styles.contextWrapper}>
                                {cocTranslation?.cocContent?.noteParagraph || `Prior authorization may be required before any service can be performed. Parts 
                                    and service currently covered under the manufacturers warranty will be provided by the manufacturer. This Contract is subject to the conditions and provisions set throughout
                                    this document and in the state specific provisions, if any attached. Please read this Certificate of Coverage carefully.`}
                            </p>
                        </Row>
                        <Row className='m-0'>
                            <Row className={`${styles.wrapperContainerHeader} mb-3`}>
                                {cocTranslation?.cocContent?.closing || 'Thank You!'}
                            </Row>
                            <Row className='m-0'>
                                <p className={styles.contextWrapper}>
                                    {cocTranslation?.cocContent?.closingParagraph || `Personal Information. You agree that We may collect, use and disclose the personal information You provide in connection with this Certificate for the purpose of providing services
                                        to You in connection with this Certificate, and for related service contract administration purposes. Your personal information will be kept at 650 Missouri Avenue, Jeffersonville, Indiana, 47130, USA. You also consent to Us 
                                        sharing or transferring this personal information to affiliates and underwriting partners who may process Your personal information on Our behalf for the same purposes, and to other organizations where required or permitted by law.
                                        Your personal information may be disclosed, processed and stored outside Canada, and therefore may be available to government authorities under lawful orders made there. Your personal information may also be transferred to a 
                                        third party or parties to be used for this same purpose in the event that We sell, transfer, or assign our assets or operations to such third party or parties, and in connection with due diligence for, and completion of, any 
                                        such transaction or any financing transaction by us involving such assets or operations.`}
                                </p>
                            </Row></Row>
                    </div>
                </div>
            </Card>
        </Container>
    )
}

export default CertificateCoverage;
