import React, { Component } from "react";
import { Card, CardBody, Col, FormGroup, Row, TabContent, TabPane, Nav, NavItem, NavLink, Container } from "reactstrap";
import styles from "./DealerProfile.module.css";
import classNames from "classnames";
import DealerHeader from "./DealerHeader/DealerHeader";
import { DealerProfileInformation } from "./DealerProfileInformation";
import { AdvantageCampaigns } from '../AdvantageCampaigns/AdvantageCampaigns';
import { ViewCampaign } from '../ViewCampaign/ViewCampaign';
import PageLoading from "../shared/PageLoading/PageLoading";
import EditButton from "../shared/EditButton/EditButton";
import { SessionContext } from "../../context/SessionContext";
import { dataLayerPush } from '../../components/Global/helpers';
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import ChangePassword from "../../Screens/ChangePassword";
import { CardDetails } from '../Sales/PaymentInformation/CardDetails';
import { PaymentMethod } from '../Sales/PaymentInformation/PaymentMethod';
import { PaymentOptionType, PaymentEntityType } from '../Global/PaymentConstants';
import { ReactComponent as VisaIcon } from '../../assets/images/cc-visa.svg';
import { ReactComponent as AmexIcon } from '../../assets/images/cc-amex.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/images/cc-discover.svg';
import { ReactComponent as MastercardIcon } from '../../assets/images/cc-mastercard.svg';
import { CoveragePreferences } from './CoveragePreferences/CoveragePreferences';
import { EmailPreferenceId as EmailPref } from '../Global/EmailPreferenceId';
import { accountDocumentTypes } from '../Global/DocumentHelper';
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import Cookies from "js-cookie";

const addressModel = {
  stateId: 0,
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: ""
}

const defaultModel = {
  dealerName: "",
  dba: "",
  dealerStatus: "",
  advantageProgramStatusId: 4,
  advantageProgramName: "",
  dealerId: 0,
  clientDealerId: "",
  physicalAddress: addressModel,
  mailingAddress: addressModel,
  emailAddress: "",
  companyPhone: "",
  companyFax: "",
  country: "",
  taxId: "",
  licenseNumber: "",
  isFloridaDealer: false,
  contactPreferenceTypeOptions: [],
  contactTypeOptions: [],
  languageOptions: [],
  stateOptions: [],
  contacts: [],
  languageId: 0,
  servicer: {},
  isAdvantageEligible: false,
  isLennoxDealer: false,
  accountId: 0,
  isAdvantageCampaignVisible: false,
  countryId: 1,
  dealerProgramId: 0,
  isInvoiceDealer: false,
  dealerDocumentLinks: [],
  masterProgramId: 0,
  emailPreferences: []
}

const Tabs = {
  BillingPreference: 4
}
const labelStyle = `${styles.commSwitchProfileText} form-label`;
export class DealerProfile extends Component {
  constructor(props) {
    super(props);

        this.state = {
            dealerId: 0,
            profileModel: defaultModel,
            profileLoaded: false,
            campaignData: [],
            dealerIsEnrolledInProgram: false,
            tierData: [],
            coverageData: [],
            tableData: [],
            updatedRowData: [],
            isAdvantageCampaignVisible: true,
            queueContact: null,
            showPasswordScreen: false,
            editPaymentInfo: false,
            billingAddressText: "",
            preferredCard: [],
            paymentPreference: "",
            dealerTermAcceptedDate: "",
            dateFormat: this.props.isCanada === true ? "DD/MM/YYYY" : "MM/DD/YYYY",
            preferredCardLastFour: "",
            hasPreferredCard: false,
            showCardDetails: false,
            addNewCard: false,
            billingAddress: {},
            isAutoPay: false,
            autoPayCheck: false,
            dealerPaymentOptionBillingAddr: {
                advPrefCard: {},
                nonAdvPrefCard: {},
                savedCard: {}
            },
            dealerProfile: {},
            paymentOptionType: PaymentOptionType,
            nonAdvPrefType: PaymentOptionType.saved,
            currentNonAdvPrefType: PaymentOptionType.saved,
            triggerClearCard: false,
            preferredCards: [],
            emailPreferences: [],
            updating: false,
            spinnerStatus: false,
            paymentOptions: []
        };
    }

  getPaymentDetails = async () => {
    const { profileModel } = this.state;
    const partyId = profileModel.partyId === undefined ? this.state.dealerProfile.partyId : profileModel.partyId;
    var res = await fetch(`party/PaymentOptions/${partyId}`);
    if (res.status === 200) {
      var data = await res.json();
      let preferred = [];
      let selectedCard = "";
      let billingAddress = "";

      if (data.length > 0) {
        preferred = data.filter(x => x.preferredOptionType === PaymentOptionType.advantagePreferred);
        let preferredCard = data.filter(x => x.preferredOptionType === PaymentOptionType.preferred);
        this.setState({ preferredCards: preferredCard });

        let billingAddressText = "N/A";

        if (preferred.length <= 0) {
          preferred = data;
        }

        if (preferred.length > 0) {
          selectedCard = preferred[0];
          let transIds = [selectedCard.transactionId];
          var csrfToken = Cookies.get("X-CSRF-TOKEN");
          var res2 = await fetch(`payment/GetCardInfoByTransactionIds`,
            {
              method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Verification': csrfToken
                },
              body: JSON.stringify(transIds)
            }
          );

          if (res2.status === 200) {
            var data2 = await res2.json();
            if (data2 != null) {
              data2 = data2[0].transactionModel;
              billingAddress = {
                address1: data2.address1 == null ? "" : data2.address1,
                address2: data2.address2 == null ? "" : data2.address2,
                city: data2.city == null ? "" : data2.city,
                state: data2.stateProvince == null ? "" : data2.stateProvince,
                stateAbbrev: data2.stateProvince == null ? "" : data2.stateProvince,
                zip: data2.zipPostalCode == null ? "" : data2.zipPostalCode,
              }
              if (billingAddress != null) {
                billingAddressText = `${billingAddress.address1}, ${billingAddress.address2} ${billingAddress.city}, ${billingAddress.state} ${billingAddress.zip}`;
              }
            }
          }
        }


        this.setState({
          preferredCard: preferred,
          preferredCardLastFour: selectedCard.lastFour,
          hasPreferredCard: true,
          billingAddressText: billingAddressText,
          billingAddress: billingAddress,
        });
      }
    }
  }

  getVendorIcon = (vendor) => {
    switch (vendor) {
      case "amex":
        return <AmexIcon />
        break;
      case "discover":
        return <DiscoverIcon />
        break;
      case "mastercard":
        return <MastercardIcon />
        break;
      case "visa":
        return <VisaIcon />
        break;
      default:
        return <VisaIcon />
        break;
    }
  }

  getActiveTab = () => {
    try {
      return this.props.state.activeTab;
    } catch (e) {
      return null;
    }
  }

  toggleTab = (profileTabId) => {
    let blnAdv = !this.state.dealerIsEnrolledInProgram;

    if (profileTabId === 2) {
      if (this.refs.viewCampaign?.refreshCards != undefined) {
        this.refs.viewCampaign.refreshCards();
      }
    }
    else if (profileTabId === 4) {
      this.getCardDetails();
    }

    this.context.tabDispatch("UPDATE_TAB", {
      activeProfileTab: profileTabId, 
      isAdvantageCampaignVisible: blnAdv
    })
  }

  getAdvantageStatus = () => {
    switch (this.state.profileModel.advantageProgramStatusId) {
      case 1:
        return 'ENROLLED_ACTIVE';
      case 2:
        return 'ENROLLED_PENDING';
      case 3:
        return 'ENROLLED_NO_CAMPAIGN';
      case 4:
        return 'UNENROLLED';
      default:
        return '';
    }
  }

  componentDidMount() {
    dataLayerPush('Pageview', {
      pageTitle: "Profile",
      pagePath: "/profile"
    })
    this.setState({
      dealerId: this.context.getProfile().dealerId,
      dealerProfile: this.context.getProfile()
    }, () => {
      this.pullDealerInfo();
      this.getAllTier();
      this.getAllCoverage();
      this.getActiveTab();
      this.getPaymentDetails();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.state !== this.props.state) {
      this.setState({ activeProfileTab: this.props.state.activeTab });
    }
  }

  pullDealerInfo = async () => {
    try {
      var route = `dealer/getdealerprofile/${this.state.dealerId}`;
      let profileResponse = await fetch(route).then(response => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`Network response was not ok ${response.status}`);
      });

      let hvacQueueContact = profileResponse.contacts.filter(x => x.isPrimary == true);

      this.setState({
        profileModel: profileResponse,
        profileLoaded: true,
        queueContact: hvacQueueContact,
        emailPreferences: profileResponse.emailPreferences
      }, () => {
        this.getDealerCampaigns();
      });

    } catch (error) {
      console.error(error);
    }
  }

  getDealerCampaigns = async () => {
    try {
      const route = `dealer/GetDealerProgramEnrollment/${this.state.dealerId}/${this.state.profileModel.accountId}`;
      const response = await fetch(route);
      if (response.ok) {
        const result = await response.json();
        if (result.enrollmentStatus === 1) {

          var model = this.state.profileModel;
          if (this.state.profileModel.advantageProgramStatusId === 4) {
            model.advantageProgramStatusId = 2; //New enrollment
          }

          this.setState({
            isAdvantageCampaignVisible: false,
            dealerIsEnrolledInProgram: true,
            campaignData: result.campaigns,
            profileModel: model
          });

          return result.campaigns;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  showPaymentDetails = () => {
    let res = this.state.profileModel.isLennoxDealer ? false : !this.state.profileModel.isInvoiceDealer;
    return res;
  }

  updateCampaignData = (data) => {
    this.setState({ campaignData: data })
  }

  updateTableData = (data) => {
    this.setState({ tableData: data })
  }

  updateRow = (data) => {
    this.setState({ updatedRowData: data })
  }

  getAllTier = async () => {
    try {
      const route = `tier/getalltiers`;
      const response = await fetch(route);
      const tier = await response.json();
      if (tier.length != 0) {
        this.setState({
          tierData: tier,

        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  getAllCoverage = async () => {
    try {
      const route = `tier/getallcoverage`;
      const response = await fetch(route);
      const coverage = await response.json();
      if (coverage.length != 0) {
        this.setState({
          coverageData: coverage,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  getDocumentPath = (documentId, key = '') => {
    var response = '/#';

    var profileModel = this.state.profileModel;
    if (profileModel != null && profileModel.dealerDocumentLinks != null) {
      var docLinks = profileModel.dealerDocumentLinks;
      response = docLinks[documentId] != null ? docLinks[documentId].find(d => typeof d !== 'undefined' && d.includes(key)) ?? "" : "";
    }

    return response;
  }

  updateDealerContacts = (contacts) => {
    this.setState({
      queueContact: contacts
    });
    const profile = this.context.getProfile();
    profile.contacts = contacts;
    this.context.setProfile(profile);
  }

  updateDealerConfiguration = (configuration) => {
    const profile = this.context.getProfile();
    profile.dealerConfigurations = profile.dealerConfigurations.filter(c => c.dealerAttributeId !== configuration.dealerAttributeId);
    profile.dealerConfigurations.push(configuration);
    this.context.setProfile(profile);
  }

  setPasswordScreen = () => {
    this.setState({ showPasswordScreen: !this.state.showPasswordScreen });
  }

  toggleCardDetails = (show) => {
    this.setState(prev => ({
      showCardDetails: show
    }));
  }

  getCardDetails = (cancelTriggered = false) => {
    const { dealerProfile } = this.state;
    const { partyId } = dealerProfile;
    let allCards = [];

    fetch(`party/PaymentOptions/${partyId}`)
      .then(res => res.json())
      .then(data => {
        let selectedCard = [];

        Promise.all(
          Object.keys(PaymentOptionType).map(key => {
            let pref = data.filter(x => x.preferredOptionType === PaymentOptionType[key]);

            if (pref.length > 0) {
              selectedCard = pref[0];

              let paymentInfo = {
                optionTypeId: PaymentOptionType[key],
                transactionId: selectedCard.transactionId,
                selectedCard: selectedCard,
              }

              allCards.push(paymentInfo);

              let transIds = [selectedCard.transactionId];
              
              var csrfToken = Cookies.get("X-CSRF-TOKEN");
              let request = {
                method: 'post',
                  headers: {
                      'Content-Type': 'application/json',
                      'CSRF-Verification': csrfToken
                  },
                body: JSON.stringify(transIds)
              }

              return fetch('payment/GetCardInfoByTransactionIds', request).then(data2 => data2.json());
            }
          })
        ).then(res2 => {
          let allOptTypeBillingAdd = res2;
          let allCardInfo = {};

          allCardInfo = allOptTypeBillingAdd.map(a => {
            if (a != undefined) {
              let cc = a[0];
              let tm = a[0].transactionModel;
              let paymentInfo = allCards.find(cc => cc.transactionId == tm.transactionId);

              let addr = {
                ...paymentInfo,
                address1: tm.address1 ?? '',
                address2: tm.address2 ?? '',
                city: tm.city ?? '',
                state: tm.stateProvince ?? '',
                stateAbbrev: tm.stateProvince ?? '',
                zip: tm.zipPostalCode ?? '',
              }

              let billingAddressText = `${addr.address1}, ${addr.address2} ${addr.city}, ${addr.state} ${addr.zip}`;
              let currentAddr = {
                ...addr,
                billingAddressText: billingAddressText
              }

              return currentAddr;
            }
          });

          allCardInfo = allCardInfo.filter(x => x != undefined);

          let advPrefCard = {};
          let nonAdvPrefCard = {};
          let savedCard = {};

          if (allCardInfo.length > 0) {
            advPrefCard = allCardInfo.filter(x => x.optionTypeId === PaymentOptionType.advantagePreferred);
            nonAdvPrefCard = allCardInfo.filter(x => x.optionTypeId === PaymentOptionType.preferred);
            savedCard = allCardInfo.filter(x => x.optionTypeId === PaymentOptionType.saved);
          }
          else {
            if (cancelTriggered)
              this.toggleCardDetails(false);
          }

          this.setState({
            dealerPaymentOptionBillingAddr: {
              advPrefCard: advPrefCard.length > 0 ? advPrefCard[0] : {},
              nonAdvPrefCard: nonAdvPrefCard.length > 0 ? nonAdvPrefCard[0] : {},
              savedCard: savedCard.length > 0 ? savedCard[0] : {},
            },
            currentPaymentOptionType: this.state.paymentOptionType.saved
          });

          if (this.refs.paymentMethod?.getPaymentOptions != undefined)
            this.refs.paymentMethod.getPaymentOptions();
        });
      });
  }

  saveCard = async () => {
    const { getCurrentTabAndSubtab } = this.context
    const { tab } = getCurrentTabAndSubtab()
    const { activeProfileTab } = tab;
    this.setState({ spinnerStatus: true });

    if ((this.showPaymentDetails() || activeProfileTab == 4) && this.state.showCardDetails) {
      if (this.refs.creditCard != null) {

        if (this.refs.creditCard.checkForValidationErrors()) {
          this.forceUpdate();
          this.setState({ spinnerStatus: false });
          return;
        }

        let cc = await this.refs.creditCard.pay();
        if (cc.isSuccessful) {
          let res = this.refs.paymentMethod.getPaymentOptions();
          this.toggleCardDetails(false);
          this.handleSelectNewCard();
          this.getCardDetails();
          this.setState({ spinnerStatus: false });
          this.setState({ editPaymentInfo: false });
          return true;
        }
      }
    }
  }

  handleSelectNewCard = async () => {
    const { profileModel } = this.state;
    const partyId = profileModel.partyId ?? this.state.dealerProfile.partyId;
    const optionTypeFilter = [PaymentOptionType.saved, PaymentOptionType.preferred];
    let paymentOptions = [];

    fetch('party/PaymentOptions/' + partyId)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
                if (optionTypeFilter !== 0 && data.length > 0) {
                    let newData = data.filter(x => {
                        return optionTypeFilter.includes(x.preferredOptionType);
                    });
                    paymentOptions = newData;
                    const lastIndex = paymentOptions.length - 1;
                    const selectedCard = paymentOptions.find(c => c.preferredOptionType === PaymentOptionType.preferred);

                    // deselect selected card
                    const requestOptions = {
                      method: "PUT",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                          paymentOptionId: selectedCard?.paymentOptionId,
                          paymentOptionTypeId: PaymentOptionType?.saved,
                          transactionId: selectedCard?.transactionId,
                          partyId: partyId
                      })
                    };
                    fetch(`party/UpdatePaymentOption`, requestOptions);

                    //select last entered card
                    fetch(`party/UpdatePaymentOption`, {
                      method: 'PUT',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                          paymentOptionId: paymentOptions[lastIndex]?.paymentOptionId,
                          paymentOptionTypeId: PaymentOptionType?.preferred,
                          transactionId: paymentOptions[lastIndex]?.transactionId,
                          partyId: partyId
                      })
                    });                    
                }
            });
        }
    });
    
  }

  handleNotification = async (emailPreferenceId) => {
    var preferences = this.state.emailPreferences;
    var pref = preferences.find(p => p.emailPreferenceId === emailPreferenceId);
    if (!pref) {
      var newPref = {
        emailPreferenceMappingId: 0,
        partyId: this.state.profileModel.partyId,
        emailPreferenceId: emailPreferenceId,
        disabled: true,
        modifiedBy: this.state.profileModel.dealerName,
        createdBy: this.state.profileModel.dealerName,
      }
      var finalPref = await this.updateEmailPreference(newPref);
      if (finalPref) {
        preferences.push(finalPref);
      }
    } else {
      pref.disabled = !pref.disabled;
      await this.updateEmailPreference(pref);
    }
    this.setState({ updating: false, emailPreferences: preferences });
  }

  updateEmailPreference = async (preference) => {
    try {
      var res = await fetch('party/UpdateEmailPreference', {
        method: 'put',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(preference)
      });
      const json = await res.json();
      return json;
    } catch {
      return null;
		}
	}

  render() {
    const { getCurrentTabAndSubtab } = this.context
    const { tab } = getCurrentTabAndSubtab()
    const { activeProfileTab } = tab
  
    let { dealerName, dba, dealerStatus, dealerId, clientDealerId, legacyDealerId,
      isAdvantageEligible, isLennoxDealer, companyPhone, emailAddress } = this.state.profileModel;

    const { editPaymentInfo, showPasswordScreen, showCardDetails } = this.state;

    const activeTabClass = (profileTabId = 0) => {
      return activeProfileTab === profileTabId ? styles.activeNavitem : "";
    }

    const isCanada = this.state.profileModel.country == "Canada" ? true : false

    if (this.state.profileModel.dealerId == 0 || this.state.profileModel.legacyDealerId == null) {
      return (
        <PageLoading />
      );
    }

    const { accountName } = this.context;

    let advantageProgramName = getBrandContent(accountName).advantageProgramName;

    const isCampaignNotEnrolled = this.getAdvantageStatus() === ('UNENROLLED') || this.getAdvantageStatus() === ('ENROLLED_NO_CAMPAIGN');
    const { physicalAddress, mailingAddress } = this.state.profileModel;

    const allCardInfo = this.state.dealerPaymentOptionBillingAddr;
    let advPrefCardExist = false;
    let nonAdvPrefCardExist = false;

    let advPrefCard = {
      cardDisplay: '',
      cardVendorIcon: '',
      billingAddressText: ''
    };

    let nonAdvPrefCard = {
      cardDisplay: '',
      cardVendorIcon: '',
      billingAddressText: ''
    };

    if (allCardInfo != undefined) {
      if (allCardInfo.advPrefCard?.optionTypeId != undefined) {
        advPrefCardExist = true;
        advPrefCard.cardDisplay = <p>ending with {allCardInfo.advPrefCard.selectedCard.lastFour}</p>
        advPrefCard.cardVendorIcon = this.getVendorIcon(allCardInfo.advPrefCard.selectedCard.vendor);
        advPrefCard.billingAddressText = allCardInfo.advPrefCard.billingAddressText;
      }

      if (allCardInfo.nonAdvPrefCard?.optionTypeId != undefined) {
        nonAdvPrefCardExist = true;
        nonAdvPrefCard.cardDisplay = <p>ending with {allCardInfo.nonAdvPrefCard.selectedCard.lastFour}</p>
        nonAdvPrefCard.cardVendorIcon = this.getVendorIcon(allCardInfo.nonAdvPrefCard.selectedCard.vendorIcon);
        nonAdvPrefCard.billingAddressText = allCardInfo.nonAdvPrefCard.billingAddressText;
      }
    }

    return (
      <Container className="contentWrapper">
        <DealerHeader
          dealerName={dealerName}
          dbaName={dba}
          dealerStatus={dealerStatus}
          advantageStatus={this.getAdvantageStatus()}
          dealerId={isLennoxDealer ? clientDealerId : legacyDealerId === 0 ? '' : legacyDealerId}
          displayAdvantageStatus={isAdvantageEligible}
          programName={advantageProgramName} />
        <Card>
          <div >
            <Nav tabs className={styles.tabsProfileInfo} role="tablist">
              <NavItem id={activeTabClass(1)} className={classNames("col-lg-2 col-12 p-0", styles.navItem)} aria-selected={activeProfileTab === 1} aria-labelledby="idtab1">
                <NavLink className={classNames({ active: activeProfileTab === 1, 'border-0': activeProfileTab === 1 }, styles.navLink)} onClick={() => { this.toggleTab(1); }}>
                  Dealer Information
                </NavLink>
              </NavItem>
              {isAdvantageEligible === true &&
                <NavItem id={activeTabClass(2)} className={classNames("col-lg-2 col-12 p-0", styles.navItem, isCampaignNotEnrolled ? styles.notEnrolledStyle : '')} aria-selected={activeProfileTab === 2} aria-labelledby="idtab2">
                  <NavLink className={classNames({ active: activeProfileTab === 2, 'border-0': activeProfileTab === 2 }, styles.navLink)} onClick={() => { this.toggleTab(2); }}>
                    {advantageProgramName} Campaigns
                  </NavLink>
                </NavItem>}

              <NavItem id={activeTabClass(3)} className={classNames("col-lg-2 col-12 p-0", styles.navItem)} aria-selected={activeProfileTab === 3} aria-labelledby="idtab3">
                <NavLink className={classNames({ active: activeProfileTab === 3, 'border-0': activeProfileTab === 3 }, styles.navLink)} onClick={() => { this.toggleTab(3); }}>
                  Coverage Preferences
                </NavLink>
              </NavItem>
              <NavItem id={activeTabClass(4)} className={classNames("col-lg-2 col-12 p-0", styles.navItem)} aria-selected={activeProfileTab === 3} aria-labelledby="idtab4">
                <NavLink className={classNames({ active: activeProfileTab === 4, 'border-0': activeProfileTab === 4 }, styles.navLink)} onClick={() => { this.toggleTab(4); }}>
                  Billing Preferences
                </NavLink>
              </NavItem>
              <NavItem id={activeTabClass(5)} className={classNames("col-lg-2 col-12 p-0", styles.navItem)} aria-selected={activeProfileTab === 4} aria-labelledby="idtab5">
                <NavLink className={classNames({ active: activeProfileTab === 5, 'border-0': activeProfileTab === 5 }, styles.navLink)} onClick={() => { this.toggleTab(5); }}>
                  Communications
                </NavLink>
              </NavItem>
              <NavItem id={activeTabClass(6)} className={classNames("col-lg-2 col-12 p-0", styles.navItem)} aria-selected={activeProfileTab === 5} aria-labelledby="idtab6">
                <NavLink className={classNames({ active: activeProfileTab === 6, 'border-0': activeProfileTab === 6 }, styles.navLink)} onClick={() => { this.toggleTab(6); }}>
                  Security
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeProfileTab}>
              <TabPane tabId={1} aria-labelledby="idtab1">
                <DealerProfileInformation
                  profileModel={this.state.profileModel}
                  profileLoaded={this.state.profileLoaded}
                  updateDealerContacts={this.updateDealerContacts}
                />
              </TabPane>
              <TabPane tabId={2} aria-labelledby="idtab2">
                <Row>
                  <Col sm="12">
                    {this.state.isAdvantageCampaignVisible &&
                      <AdvantageCampaigns
                        advantageName={advantageProgramName}
                        dealerProgramId={this.state.profileModel.dealerProgramId}
                        dealerId={this.state.dealerId}
                        accountId={this.state.profileModel.accountId}
                        country={this.state.profileModel.country}
                        phone={this.state.queueContact.length == 0 ? companyPhone : this.state.queueContact[0].phoneNumber}
                        email={this.state.queueContact.length == 0 ? emailAddress : this.state.queueContact[0].email}
                        partyId={this.state.profileModel.partyId}
                        mailingAddress={this.state.profileModel.mailingAddress}
                        billingAddress={this.state.profileModel.mailingAddress}
                        profile={this.state.profileModel}
                        getDealerCampaigns={this.getDealerCampaigns}
                        termsAndConditionsPath={this.getDocumentPath(accountDocumentTypes.AdvantageTermsAndConditions)}
                        dealerName={dealerName}
                        campaignName={getBrandContent(accountName).programName}
                        isLennoxDealer={isLennoxDealer}
                        savedCard={this.state.preferredCards}
                      />
                    }
                    {this.state.dealerIsEnrolledInProgram &&
                      <ViewCampaign
                        ref="viewCampaign"
                        advantageProgramName={advantageProgramName}
                        campaignData={this.state.campaignData}
                        tierData={this.state.tierData}
                        coverageData={this.state.coverageData}
                        getDealerCampaigns={this.getDealerCampaigns}
                        updateCampaignData={this.updateCampaignData}
                        updateTableData={this.updateTableData}
                        tableData={this.state.tableData}
                        updateRow={this.updateRow}
                        updatedRowData={this.state.updatedRowData}
                        isCanada={isCanada}
                        dealerId={this.state.dealerId}
                        isInvoiceDealer={this.state.profileModel.isInvoiceDealer}
                        partyId={this.state.profileModel.partyId}
                        accountId={this.state.profileModel.accountId}
                        profileModel={this.state.profileModel}
                        savedCard={this.state.preferredCards}
                      />
                    }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={3} aria-labelledby="idtab3">
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <CoveragePreferences
                        advantageName={advantageProgramName}
                        profileModel={this.state.profileModel}
                        updateDealerConfiguration={this.updateDealerConfiguration}
                        accountName={accountName.toLowerCase()}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </TabPane>
              <TabPane tabId={4} aria-labelledby="idtab4" >
                <CardBody>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <div className={classNames(styles.companyInfoHead, "mb-0")}>
                        <h2 className="h5 subHead">Preferred Payment Method</h2>
                        {(!editPaymentInfo && nonAdvPrefCardExist) 
                          && <EditButton onClick={() => this.setState({ editPaymentInfo: true })} />
                        }
                      </div>
                      <p className="pb-2">
                        Choose the default payment method you will like pre-selected on all future sales
                        {accountName.toLowerCase() !== 'ge' && ` (excluding ${this.state.profileModel.advantageProgramName}).`}
                      </p>
                      {(!editPaymentInfo && nonAdvPrefCardExist) ? (<Row className={classNames("multiColWrapper", styles.paymentRow)}>
                        <Col xs="12" md="4" lg="2" className="col-5ths">
                          <FormGroup>
                            <label className="form-label">Payment Method</label>
                            <div className={styles.payMethod}>
                              {nonAdvPrefCard.cardVendorIcon}
                              {nonAdvPrefCard.cardDisplay}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="4" lg="2" className="col-5ths">
                          <FormGroup>
                            <label className="form-label">Billing Address</label>
                            <p className="mb-0">
                              {nonAdvPrefCard.billingAddressText}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                      </Row>) : (
                          <Col lg="12" md="12" sm="12" className="p-0">
                            <PaymentMethod
                              ref="paymentMethod"
                              atAdvantageCampaign={true}
                              showPaymentMethod={false}
                              showCardDetails={this.state.editPaymentInfo || !nonAdvPrefCardExist}
                              showPaymentDetails={this.showPaymentDetails()}
                              toggleEdit={() => this.setState({ editPaymentInfo: nonAdvPrefCardExist ? false : true, showCardDetails: false })}
                              partyId={this.state.profileModel.partyId}
                              preferredType={PaymentOptionType.preferred}
                              toggleCardDetails={this.toggleCardDetails}
                              updatePaymentDetails={this.getCardDetails}
                              cardSelected={this.state.showCardDetails}
                              optionTypeFilter={[PaymentOptionType.saved, PaymentOptionType.preferred]}
                              accountName={accountName.toLowerCase()}
                              billingAddress={nonAdvPrefCard.billingAddressText}
                            >
                              {/*accountName.toLowerCase() !== 'ge' && 
                                <p>
                                  Please provide payment information that will be saved on file as your preferred method of payment for future use.
                                  This payment method will not be automatically charged, only stored for future use, unless you enroll in autopay.
                                </p>
                              */}

                            </PaymentMethod>
                            {showCardDetails && <>
                              <hr className="my-2" />
                              <CardDetails
                                ref="creditCard"
                                visible={true}
                                isCanadaState={isCanada}
                                amount={0}
                                billingAddress={physicalAddress}
                                mailingAddress={mailingAddress}
                                partyId={this.state.profileModel.partyId}
                                savedCardType={PaymentOptionType.saved}
                                showPaymentDetails={this.showPaymentDetails()}
                                accountId={this.state.profileModel.accountId}
                                hideSaveCard={true}
                                saveOnly={true}
                                chargedEntityIds={[]}
                                chargedEntityType={PaymentEntityType.NA}
                                showLabel={false}
                                countryId={this.state.profileModel.countryId}
                                optionTypeFilter={[PaymentOptionType.saved, PaymentOptionType.preferred]}
                                updatePaymentDetails={this.getCardDetails}
                              />
                            </>}
                          </Col>
                        )}
                    </Col>
                  </Row>
                  {showCardDetails && <Row>
                    <Col lg="12" md="12" sm="12" className="mt-2">
                      <div className="btnWrapper">
                        <button className="btnLink" onClick={() => { this.getCardDetails(true) }}>Cancel</button>
                        <SpinnerButton
                          onClick={() => { this.saveCard() }}
                          disabled={this.state.spinnerStatus}
                          spinning={this.state.spinnerStatus}
                          type={"primary"}
                          text={"Save"}
                        />
                      </div>
                    </Col>
                  </Row>}
                </CardBody>
              </TabPane>
              <TabPane tabId={5} aria-labelledby="idtab4" >
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <h2 className="h5">Email Notifications</h2>
                      <div id="commPreferenceProfile">
                        <div className="mb-2">
                          <div className={labelStyle}>
                            Sales Entry Confirmation
                          </div>
                          <div className={styles.commSwitchProfileInput}>
                            <label className={classNames(styles.commSwitchProfile)} for='commOption1'>
                              <input
                                type="checkbox"
                                id='commOption1'
                                name="commOption1"
                                disabled={this.state.updating}
                                onChange={() => {
                                  this.setState({ updating: true });
                                  this.handleNotification(EmailPref.SalePurchaseConfirmation);
                                }}
                                checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.SalePurchaseConfirmation)?.disabled}
                              />
                              <span className={classNames(styles.slider, styles.round)} />
                            </label>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className={labelStyle}>
                            {advantageProgramName} Contract Payment Confirmation
                            </div>
                          <div className={styles.commSwitchProfileInput}>
                            <label className={classNames(styles.commSwitchProfile)} for='commOption2'>
                              <input
                                type="checkbox"
                                id='commOption2'
                                name="commOption2"
                                disabled={this.state.updating}
                                onChange={() => {
                                  this.setState({ updating: true });
                                  this.handleNotification(EmailPref.ContractPaymentConfirmation);
                                }}
                                checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.ContractPaymentConfirmation)?.disabled}
                              />
                              <span className={classNames(styles.slider, styles.round)} />
                            </label>
                          </div>
                        </div>
                        {accountName.toLowerCase() !== 'ge' &&
                        <>
                          <div className="mb-2">
                            <div className={labelStyle}>
                              {advantageProgramName} Campaign Expiring Second Reminder
                              </div>
                            <div className={styles.commSwitchProfileInput}>
                              <label className={classNames(styles.commSwitchProfile)} for='commOption3'>
                                <input
                                  type="checkbox"
                                  id='commOption3'
                                  name="commOption3"
                                  disabled={this.state.updating}
                                  onChange={() => {
                                    this.setState({ updating: true });
                                    this.handleNotification(EmailPref.CampaignExpiringSecondReminder);
                                  }}
                                  checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.CampaignExpiringSecondReminder)?.disabled}
                                />
                                <span className={classNames(styles.slider, styles.round)} />
                              </label>
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className={labelStyle}>
                              {advantageProgramName} Campaign Expiring Third Reminder
                              </div>
                            <div className={styles.commSwitchProfileInput}>
                              <label className={classNames(styles.commSwitchProfile)} for='commOption4'>
                                <input
                                  type="checkbox"
                                  id='commOption4'
                                  name="commOption4"
                                  disabled={this.state.updating}
                                  onChange={() => {
                                    this.setState({ updating: true });
                                    this.handleNotification(EmailPref.CampaignExpiringThirdReminder);
                                  }}
                                  checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.CampaignExpiringThirdReminder)?.disabled}
                                />
                                <span className={classNames(styles.slider, styles.round)} />
                              </label>
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className={labelStyle}>
                              {advantageProgramName} Campaign Expired Second Reminder
                              </div>
                            <div className={styles.commSwitchProfileInput}>
                              <label className={classNames(styles.commSwitchProfile)} for='commOption5'>
                                <input
                                  type="checkbox"
                                  id='commOption5'
                                  name="commOption5"
                                  disabled={this.state.updating}
                                  onChange={() => {
                                    this.setState({ updating: true });
                                    this.handleNotification(EmailPref.CampaignExpiredSecondReminder);
                                  }}
                                  checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.CampaignExpiredSecondReminder)?.disabled}
                                />
                                <span className={classNames(styles.slider, styles.round)} />
                              </label>
                            </div>
                          </div>
                          <div>
                          <div className={labelStyle}>
                            {advantageProgramName} Campaign Expired Third Reminder
                            </div>
                          <div className={styles.commSwitchProfileInput}>
                            <label className={classNames(styles.commSwitchProfile)} for='commOption6'>
                              <input
                                type="checkbox"
                                id='commOption6'
                                name="commOption6"
                                disabled={this.state.updating}
                                onChange={() => {
                                  this.setState({ updating: true });
                                  this.handleNotification(EmailPref.CampaignExpiredThirdReminder);
                                }}
                                checked={!this.state.profileModel.emailPreferences.find(p => p.emailPreferenceId === EmailPref.CampaignExpiredThirdReminder)?.disabled}
                              />
                              <span className={classNames(styles.slider, styles.round)} />
                            </label>
                          </div>
                        </div>
                        </>
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </TabPane>
              <TabPane tabId={6} aria-labelledby="idtab5">
                <CardBody>
                  <Row>
                    <Col sm="12">
                      {!showPasswordScreen && (
                        <>
                          <h2 className="h5">Password</h2>
                          <button
                            className={classNames('btn-primary')}
                            onClick={this.setPasswordScreen}
                          >
                            Change Password
                          </button>
                        </>
                      )}
                      {showPasswordScreen && (
                        <ChangePassword
                          setPasswordScreen={this.setPasswordScreen} dealerId={this.state.dealerId} clientDealerId={clientDealerId}
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </TabPane>
            </TabContent>
          </div>
        </Card>
      </Container>
    );
  }
}

DealerProfile.contextType = SessionContext;